import type { StoryData } from '~/utils/types/storyblok'

export const useStoryblokHead = (
    story: StoryData,
    ignoreOGImage: boolean = false,
) => {
    let title = null
    let description = null
    let ogImagePath = null
    let ogImageContentType = null

    if (story.content) {
        const domain: string = useDomainStore().domain.domain
        const protocol: 'http' | 'https' = domain.includes('localhost')
            ? 'http'
            : 'https'

        title = story.content?.meta?.title ?? story.content?.title ?? null
        description =
            story.content?.meta?.description ??
            story.content.description ??
            null

        ogImagePath = story.content.og_image?.filename ?? null

        if (ogImagePath === null && !ignoreOGImage) {
            ogImagePath = `${protocol}://${domain}/bb/image/page/${story.slug}.png`
            ogImageContentType = 'image/png'
        }
    }

    useHead({
        title,
        meta: [
            {
                name: 'description',
                content: description,
            },
            {
                name: 'twitter:title',
                content: title,
            },
            {
                name: 'twitter:image',
                content: ogImagePath,
            },
            {
                property: 'og:image',
                content: ogImagePath,
            },
            { property: 'og:image:type', content: ogImageContentType },
            {
                name: 'twitter:description',
                content: description,
            },
            { property: 'og:type', content: 'website' },
            {
                property: 'og:title',
                content: title,
            },
            {
                property: 'og:description',
                content: description,
            },
        ],
    })
}
